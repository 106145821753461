import React, { useState, useEffect, Suspense, lazy } from 'react';
import { FaArrowRight, FaInstagram } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { FaPhp } from 'react-icons/fa';
import { FaSwift } from 'react-icons/fa';
import { FaAndroid } from 'react-icons/fa';
import { FaRust } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { FaReact, FaNodeJs, FaPython, FaAws, FaDocker, FaDatabase, FaVuejs } from 'react-icons/fa';
import { SiGraphql } from 'react-icons/si';
import { SiKubernetes } from 'react-icons/si';
import { SiTypescript } from 'react-icons/si';
import { SiExpress } from 'react-icons/si';
import { SiMicrosoftazure } from 'react-icons/si';
import { SiGooglecloud } from 'react-icons/si';
import { SiKotlin } from 'react-icons/si';
import { SiFlutter } from 'react-icons/si';
import { SiMysql } from 'react-icons/si';
import { SiMeilisearch } from 'react-icons/si';
import { SiElasticsearch } from 'react-icons/si';
import { Helmet } from 'react-helmet';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from './assets/images/logo_branca.png';
import logoRoxo from './assets/images/logo_roxo.png';
import icone from './assets/images/icone_roxo.png';
import backgroundImage from './assets/images/background_image2.webp';
import { DiRedis } from 'react-icons/di';
import FeatureCards from './FeatureCards';
import ModularArchitectureSection from './ModularArchitectureSection';
import StagesComponent from './components/StagesComponent';
import CtaSection from './components/CtaSection';
import EmployeeCarousel from './components/EmployeeCarousel';
import Academy from './components/Academy';
import MobileAppSection from './components/MobileAppSection';
import RegistrationScreen from './components/RegistrationScreen';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Lazy loading dos componentes
const CalendlyWidget = lazy(() => import('./components/CalendlyWidget'));
const Carousel = lazy(() => import('./components/Carousel'));

const handleButtonClick = (buttonName) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'ctaWhatsapp',
    buttonDetails: buttonName,
  });
};

const MainContent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const technologies = [
    { name: 'Azure', icon: SiMicrosoftazure },
    { name: 'React', icon: FaReact },
    { name: 'Node.js', icon: FaNodeJs },
    { name: 'Python', icon: FaPython },
    { name: 'AWS', icon: FaAws },
    { name: 'Docker', icon: FaDocker },
    { name: 'MongoDB', icon: FaDatabase },
    { name: 'GraphQL', icon: SiGraphql },
    { name: 'GCP', icon: SiGooglecloud },
    { name: 'Kubernetes', icon: SiKubernetes },
    { name: 'TypeScript', icon: SiTypescript },
    { name: 'PostgreSQL', icon: FaDatabase },
    { name: 'Vue.js', icon: FaVuejs },
    { name: 'Express.js', icon: SiExpress },
    { name: 'PHP', icon: FaPhp },
    { name: 'Redis ', icon: DiRedis },
    { name: 'Swift', icon: FaSwift },
    { name: 'Kotlin ', icon: SiKotlin },
    { name: 'Android ', icon: FaAndroid },
    { name: 'Flutter ', icon: SiFlutter },
    { name: 'Mysql ', icon: SiMysql },
    { name: 'Rust ', icon: FaRust },
    { name: 'Meilisearch  ', icon: SiMeilisearch },
    { name: 'Elasticsearch', icon: SiElasticsearch },
  ];

  const itemsPerSlide = 5;
  const totalSlides = Math.ceil(technologies.length / itemsPerSlide);
  const [activeSection, setActiveSection] = useState('');

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['inicio', 'case', 'process', 'deadline', 'employees', 'academy', 'tecnologies', 'contact'];
      let currentSection = '';

      sections.forEach((sectionId) => {
        const section = document.getElementById(sectionId);
        if (section && window.scrollY >= section.offsetTop - 60) {
          currentSection = sectionId;
        }
      });

      setActiveSection(currentSection);

      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % totalSlides);
    }, 5000);
    return () => clearInterval(interval);
  }, [totalSlides]);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const sections = ['inicio', 'case', 'process', 'deadline', 'employees', 'academy', 'tecnologies', 'contact'];

  return (

    <div className="font-sans">
      <Helmet>
        <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async defer></script>
      </Helmet>

      {/* Header Section */}
      <header className={`fixed w-full z-10 h-16 p-4 transition-colors duration-300 ${isScrolled ? 'bg-white text-[#4a4a4a]' : 'bg-transparent text-white'}`}>
        <div className="container mx-auto flex justify-between items-center">
          {/* Logo */}
          <div className="relative" style={{ width: '150px', height: 'auto' }}>
            <img
              src={isScrolled ? icone : logo}
              alt="Logo da Empresa"
              className={isScrolled ? "absolute top-[-45px] h-20 object-contain" : "absolute top-[-70px] h-70 object-contain"}
            />
          </div>

          {/* Menu Hamburguer para dispositivos móveis */}
          <div className="md:hidden">
            <button
              onClick={toggleMobileMenu}
              aria-label={isMobileMenuOpen ? "Fechar menu" : "Abrir menu"}
              className={`${isScrolled ? 'text-[#4a4a4a]' : 'text-[#783BFF]'} text-3xl`}>
              {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>

          {/* Menu completo para telas maiores */}
          <nav className="hidden md:block">
            <ul className="flex space-x-4 items-center text-lg font-semibold">
              <li>
                <button
                  onClick={() => scrollToSection('inicio')}
                  className={`font-montserrat ${activeSection === 'inicio' ? 'text-[#783BFF]' : isScrolled ? 'text-[#4a4a4a] hover:text-[#783BFF]' : 'text-white hover:text-[#783BFF]'} transition-colors duration-300`}
                  aria-label="botão para scroll até inicio"
                >
                  Início
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('case')}
                  className={`font-montserrat ${activeSection === 'case' ? 'text-[#783BFF]' : isScrolled ? 'text-[#4a4a4a] hover:text-[#783BFF]' : 'text-white hover:text-[#783BFF]'} transition-colors duration-300`}
                  aria-label="botão para scroll até case"
                >
                  Case
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('process')}
                  className={`font-montserrat ${activeSection === 'process' ? 'text-[#783BFF]' : isScrolled ? 'text-[#4a4a4a] hover:text-[#783BFF]' : 'text-white hover:text-[#783BFF]'} transition-colors duration-300`}
                  aria-label="botão para scroll até processos"
                >
                  Processo
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('deadline')}
                  className={`font-montserrat ${activeSection === 'deadline' ? 'text-[#783BFF]' : isScrolled ? 'text-[#4a4a4a] hover:text-[#783BFF]' : 'text-white hover:text-[#783BFF]'} transition-colors duration-300`}
                  aria-label="botão para scroll até prazos"
                >
                  Prazos?
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('employees')}
                  className={`font-montserrat ${activeSection === 'employees' ? 'text-[#783BFF]' : isScrolled ? 'text-[#4a4a4a] hover:text-[#783BFF]' : 'text-white hover:text-[#783BFF]'} transition-colors duration-300`}
                  aria-label="botão para scroll até time"
                >
                  Time
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('academy')}
                  className={`font-montserrat ${activeSection === 'academy' ? 'text-[#783BFF]' : isScrolled ? 'text-[#4a4a4a] hover:text-[#783BFF]' : 'text-white hover:text-[#783BFF]'} transition-colors duration-300`}
                  aria-label="botão para scroll até academy"
                >
                  Academy
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('tecnologies')}
                  className={`font-montserrat ${activeSection === 'tecnologies' ? 'text-[#783BFF]' : isScrolled ? 'text-[#4a4a4a] hover:text-[#783BFF]' : 'text-white hover:text-[#783BFF]'} transition-colors duration-300`}
                  aria-label="botão para scroll até tecnologias"
                >
                  Tech
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('contact')}
                  className={`font-montserrat border ${activeSection === 'contact' ? 'text-[#783BFF] border-[#783BFF]' : isScrolled ? 'text-[#4a4a4a] border-[#4a4a4a] hover:bg-[#783BFF] hover:text-white' : 'text-white border-white hover:bg-[#783BFF]'} px-4 py-2 rounded-full transition-all duration-300`}
                  aria-label="botão para scroll até contato"
                >
                  Contato
                </button>
              </li>
            </ul>
          </nav>


          {/* Menu Mobile */}
          {isMobileMenuOpen && (
            <nav className="fixed top-16 left-0 w-full bg-[#151342] z-20 md:hidden">
              <ul className="flex flex-col space-y-4 p-4">
                <li><button onClick={() => { scrollToSection('inicio'); toggleMobileMenu(); }} className="hover:text-[#783BFF] font-montserrat" aria-label="botão para scroll para o inicio">Início</button></li>
                <li><button onClick={() => { scrollToSection('case'); toggleMobileMenu(); }} className="hover:text-[#783BFF] font-montserrat" aria-label="botão para scroll até case">Serviços</button></li>
                <li><button onClick={() => { scrollToSection('process'); toggleMobileMenu(); }} className="hover:text-[#783BFF] font-montserrat" aria-label="botão para scroll para o processo">Processo</button></li>
                <li><button onClick={() => { scrollToSection('deadline'); toggleMobileMenu(); }} className="hover:text-[#783BFF] font-montserrat" aria-label="botão para scroll para o prazo">Prazos?</button></li>
                <li><button onClick={() => { scrollToSection('employees'); toggleMobileMenu(); }} className="hover:text-[#783BFF] font-montserrat" aria-label="botão para scroll para o time">Time</button></li>
                <li><button onClick={() => { scrollToSection('academy'); toggleMobileMenu(); }} className="hover:text-[#783BFF] font-montserrat" aria-label="botão para scroll para o academy">Academy</button></li>
                <li><button onClick={() => { scrollToSection('tecnologies'); toggleMobileMenu(); }} className="hover:text-[#783BFF] font-montserrat" aria-label="botão para scroll para as tecnologias">Tecnologias</button></li>
                <li><button onClick={() => { scrollToSection('contact'); toggleMobileMenu(); }} className="hover:text-[#783BFF] font-montserrat" aria-label="botão para scroll para o contato">Contato</button></li>
              </ul>
            </nav>
          )}
        </div>
      </header>


      {/* Main Text Section */}
      <section
        id="inicio"
        className="bg-gradient-to-r from-[#151342] to-[#783BFF] text-white min-h-screen flex items-center pt-16"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className="container mx-auto text-center px-4">
          <motion.h1
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            // className="text-5xl md:text-6xl font-bold mb-6 font-zendots"
            className="text-3xl md:text-6xl font-bold mb-6 font-zendots text-center"
            style={{ fontFamily: 'ZenDots' }}
          >
            Sistemas e aplicativos <span className="text-[#783BFF] font-bold">web e mobile</span>
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
            className="text-1xl md:text-2xl mb-8 font-montserrat"
          >
            Que se adaptam ao seu orçamento, com a qualidade e expertise de quem conhece as maiores techs do mercado
          </motion.p>
          <motion.a
            href="/forms"
            target="_blank"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-white text-[#783BFF] px-8 py-3 rounded-full text-lg font-semibold hover:bg-[#F4F4F4] transition duration-300 font-montserrat"
            onClick={() => handleButtonClick("Entre em contato")}
          >
            Inicie Seu Projeto Conosco <FaArrowRight className="inline ml-2" />
          </motion.a>
        </div>
      </section>
      {/* Componentes */}
      <FeatureCards />
      <ModularArchitectureSection id="case" />
      <StagesComponent id="process" />
      <CtaSection id="deadline" />
      <EmployeeCarousel id="employees" />
      <Academy id="academy" />
      <MobileAppSection />

      {/* Seção tecnologias */}
      <section id="tecnologies" className="py-20 bg-[#151342] text-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 font-zendots" style={{ fontFamily: 'ZenDots' }}>Algumas das tecnologias que usamos</h2>
          <Suspense fallback={<div>Carregando Carousel...</div>}>
            <Carousel
              technologies={technologies}
              totalSlides={totalSlides}
              currentSlide={currentSlide}
              setCurrentSlide={setCurrentSlide}
            />
          </Suspense>
        </div>
      </section>

      {/* Suspense para carregar o componente Calendly */}
      <section id="contact" className="py-20 bg-[#783BFF] text-white">
        <div className="max-w-screen-md mx-auto text-center">
          <h2 className="text-4xl font-bold mb-10" style={{ fontFamily: 'ZenDots' }}>Agende uma Reunião sem compromisso</h2>
          <Suspense fallback={<div>Carregando Calendário...</div>}>
            <CalendlyWidget />
          </Suspense>
        </div>
      </section>

      { /* Footer */}
      <footer className="bg-white py-10">
        <div className="container mx-auto px-4">
          {/* Primeira linha com logo e frase */}
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center space-x-8">
              <img src={logoRoxo} alt="Logo" className="h-32 md:h-48 w-auto" />
              <div className="text-black text-lg">
                <p className="font-bold">Transformando ideias em realidade.</p>
                <p>sonhe grande, construa maior_</p>
              </div>
            </div>

            {/* Links */}
            <div className="flex space-x-8 mt-6 md:mt-0">
              <a
                href="https://www.instagram.com/biancodetech"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#783BFF] hover:text-[#5a30cc]"
                aria-label="Visite nosso Instagram"
              >
                <FaInstagram className="h-8 w-8" />
              </a>
              <a
                href="https://www.youtube.com/@biancodetech"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#783BFF] hover:text-[#5a30cc]"
                aria-label="Visite nosso Youtube"
              >
                <FaYoutube className="h-8 w-8" />
              </a>
              <a
                href="https://www.linkedin.com/in/lincoln-biancardi-9429622a/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#783BFF] hover:text-[#5a30cc]"
                aria-label="Visite nosso LinkedIn"
              >
                <FaLinkedin className="h-8 w-8" />
              </a>
            </div>
          </div>

          {/* Contato */}
          <div className="mt-8 flex flex-col md:flex-row justify-center items-center text-gray-700 space-y-4 md:space-y-0 md:space-x-8">
            {/* Telefone com ícone do WhatsApp */}
            <div className="flex items-center space-x-2">
              <FaWhatsapp className="text-[#25D366] h-6 w-6" />
              <a
                href="/forms"
                className="text-gray-700 hover:underline font-semibold"
                aria-label="Entre em contato via WhatsApp"
                onClick={() => handleButtonClick("+55 11 93268-9884")}
              >
                +55 11 93268-9884
              </a>
            </div>

            {/* Email com ícone */}
            <div className="flex items-center space-x-2">
              <FaEnvelope className="text-[#783BFF] h-6 w-6" />
              <a
                href="mailto:suporte@biancode.com.br"
                className="text-gray-700 hover:underline font-semibold"
                aria-label="Envie um email para o suporte"
              >
                suporte@biancode.com.br
              </a>
            </div>
          </div>

          {/* Endereço e informações */}
          <div className="mt-8 flex flex-col md:flex-row justify-between items-start text-gray-700">
            <div className="text-center md:text-left">
              <h3 className="font-bold">BianCode SP</h3>
              <p>Jundiaí, São Paulo</p>
            </div>
            <div className="text-center md:text-left mt-6 md:mt-0">
              <h3 className="font-bold">BianCode ES</h3>
              <p>Guarapari, ES</p>
            </div>
            <div className="text-center md:text-left mt-6 md:mt-0">
              <h3 className="font-bold">BianCode Internacional</h3>
              <p>Em breve.</p>
            </div>
          </div>

          {/* Políticas */}
          <div className="text-center text-gray-500 mt-6">
            <a
              href="https://blog.google/intl/pt-br/novidades/por-dentro-do-google/como-funcionam-as-politicas-do-google/"
              className="hover:underline"
              aria-label="Link de Política de privacidade"
            >
              Política Global de Privacidade
            </a> |
            <a
              href="https://blog.google/intl/pt-br/novidades/por-dentro-do-google/como-funcionam-as-politicas-do-google/"
              className="hover:underline ml-2"
              aria-label="Link de Política de conduta"
            >
              Política do Código de Conduta
            </a>
          </div>

          {/* Direitos reservados */}
          <p className="text-center text-gray-500 mt-4">© BianCode, 2024. Todos os Direitos Reservados</p>
        </div>
      </footer>

      <div className="fixed right-2 bottom-4 z-50 flex-col items-end space-y-2 hidden md:flex">
        {/* Botões de Seções Flutuantes */}
        <div className="bg-white rounded-full p-2 shadow-lg flex flex-col items-center">
          {sections.map((section, index) => (
            <React.Fragment key={section}>
              <button
                onClick={() => scrollToSection(section)}
                className={`w-3 h-3 rounded-full transition-all duration-300 ${activeSection === section ? "bg-[#783BFF]" : "bg-gray-400"}`}
                aria-label="Ir para a seção início"
              ></button>
              {index < sections.length - 1 && (
                <div className="w-0.5 h-3 bg-gray-400"></div>
              )}
            </React.Fragment>
          ))}
        </div>

        {/* Ícone do WhatsApp */}
        <a
          href="https://wa.me/5511932689884"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-green-500 text-white p-2 rounded-full shadow-lg hover:bg-green-600 transition duration-300"
          aria-label="Link para o whatsapp da biancode"
        >
          <FaWhatsapp size={16} />
        </a>

        {/* Ícone do Email */}
        <a
          href="mailto:suporte@biancode.com.br" // Email de contato
          className="bg-blue-500 text-white p-2 rounded-full shadow-lg hover:bg-blue-600 transition duration-300"
          aria-label="Link para o email da biancode"
        >
          <FaEnvelope size={16} />
        </a>
      </div>
    </div>

  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/forms" element={<RegistrationScreen />} />
      </Routes>
    </Router>
  );
};

export default App;
