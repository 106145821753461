import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { motion } from "framer-motion";
import boxImage from './assets/images/box_modules.png';

const handleButtonClick = (buttonName) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'ctaWhatsapp',
    buttonDetails: buttonName,
  });
};

const ModularArchitectureSection = ({ id }) => {

  return (
    <section id={id} className="bg-[#151342] min-h-[70vh] flex flex-col justify-center items-center p-4 relative overflow-hidden">
      <motion.div
        className="absolute inset-0 -z-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <svg
          className="w-full h-full"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0 C30,50 70,50 100,0 V100 H0 V0Z"
            fill="rgba(244,244,244,0.05)"
          />
        </svg>
      </motion.div>

      <motion.h1
        className="text-3xl md:text-5xl font-bold text-center text-[#F4F4F4] mb-10 mt-12"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
        style={{ fontFamily: 'ZenDots' }}
      >
        Como conseguimos entregar o que prometemos?
      </motion.h1>

      <div className="flex flex-col md:flex-row items-center justify-between max-w-6xl mx-auto">
        <motion.div
          className="md:w-1/2 mb-4 md:mb-0 md:pr-8"
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <p className="text-[#F4F4F4] text-lg leading-relaxed mb-6">
            Nossa <span className="text-[#F4F4F4] font-bold">arquitetura de módulos</span> nos permite reaproveitar tecnologias desenvolvidas em iniciativas anteriores. Essa abordagem não só reduz custos, mas também acelera o processo de desenvolvimento, garantindo que você obtenha <span className="text-[#F4F4F4] font-bold">soluções mais rápidas</span> e eficientes sem comprometer a <span className="text-[#F4F4F4] font-bold">qualidade</span>.
          </p>
          <p className="text-[#F4F4F4] text-lg leading-relaxed">
            Essa metodologia inovadora é a razão pela qual podemos oferecer entregas rápidas, dentro do orçamento e com <span className="text-[#F4F4F4] font-bold">manutenção reduzida</span>. Com a <span className="text-[#F4F4F4] font-bold">BianCode</span>, você tem a certeza de que sua ideia se tornará realidade de forma ágil e eficiente!
          </p>
        </motion.div>

        <motion.div
          className="md:w-1/2"
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <img src={boxImage} alt="White robotic hand with transparent background" className="w-full h-auto rounded-lg" />
        </motion.div>
      </div>

      <motion.div
        className="mt-12 text-xl font-bold text-[#F4F4F4] transition-all duration-300 flex items-center justify-center"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <a
          onClick={() => handleButtonClick("Faça um orçamento sem compromisso")}
          href="/forms"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center hover:text-[#783BFF] transition duration-300 mb-12"
          aria-label="Link para o whatsapp da biancode"
        >
          Faça um orçamento sem compromisso <FaArrowRight className="inline-block ml-2" />
        </a>
      </motion.div>
    </section>
  );
};

export default ModularArchitectureSection;
