import React from "react";
import { FaBolt, FaRocket, FaPiggyBank } from "react-icons/fa";

const FeatureCards = () => {
  const cards = [
    {
      title: "3x",
      icon: <FaBolt className="text-3xl text-purple-300" />,
      subtitle: "Mais rápido",
      description: "Abordagem ágil e práticas otimizadas, entrega do projeto completo em até 60 dias!",
      color: "from-[#151342] to-[#783BFF]",
    },
    {
      title: "100%",
      icon: <FaRocket className="text-3xl text-purple-400" />,
      subtitle: "Dentro do seu orçamento",
      description: "Projeto adaptado as suas necessidades financeiras. Sem surpresas, sem custos adicionais. O que foi acordado, será entregue!",
      color: "from-[#151342] to-[#783BFF]",
    },
    {
      title: "50%",
      icon: <FaPiggyBank className="text-3xl text-purple-500" />,
      subtitle: "Menos custo em novas implementações",
      description: "Nas futuras implementações, você economiza até 50%, com atualizações mais rápidas e eficientes pela nossa familiaridade com o projeto.",
      color: "from-[#151342] to-[#783BFF]"
    },
  ];

  return (
    <div className="container mx-auto px-4 py-16 bg-purple-50">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {cards.map((card, index) => (
          <div
            key={index}
            className="relative overflow-hidden rounded-2xl shadow-2xl transform hover:scale-105 transition-all duration-300"
            aria-label={`Feature card: ${card.subtitle}`}
          >
            <div className={`absolute inset-0 bg-gradient-to-br ${card.color} opacity-90`}></div>
            <div className="relative p-8 flex flex-col h-full z-10">
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-5xl font-extrabold text-white">{card.title}</h2>
                <div className="bg-purple-100 p-3 rounded-full">{card.icon}</div>
              </div>
              <h3 className="text-2xl font-bold text-white mb-4">{card.subtitle}</h3>
              <p className="text-white text-lg flex-grow">{card.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeatureCards;
